import React from 'react'
import HomeBody from '../Components/Home'
import Nav from '../Components/Nav'

const Home = () => {
  return (
    <>
        <Nav/>
        <HomeBody/>     
    </>
  )
}

export default Home